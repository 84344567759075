<template>
  <teleport to="#nav">
    <NavComp />
  </teleport>
</template>

<script>
import NavComp from '@/components/nav-comp.vue'

export default {
  name: 'App',
  components: {
    NavComp
  }
}
</script>

<style lang="sass" src="@/assets/sass/main.sass"></style>
